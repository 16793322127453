.fs(@font-size) {
    font-size: @font-size / 37.5rem;
}

@info: #cdac7a;







































.html_4 img             {display: inline-block!important;max-width: 100%!important;}
.html_4 p,
.html_4 span,
.html_4 a {.fs(14)!important;line-height: .6rem!important;}
.html_4 section {width: 100%!important;zoom: 1;box-sizing: border-box;margin-left: 0!important;margin-right: 0!important;
    &:before,
    &:after         { content: ""; display: table; }
    &:after         { clear: both; }
}
