.fs(@font-size) {
    font-size: @font-size / 37.5rem;
}

@info: #cdac7a;






















.html_6         {
    div         {.fs(14);margin-bottom: .2rem;}
    p           {.fs(14);white-space: normal;line-height: .66rem;color: #666;}
    img         {display: inline-block!important;max-width: 100%!important;}
}
