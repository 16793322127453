.html_13 .first .zp {
  padding: 0 .4rem;
  background: #fafafa;
  font-size: 0.37333333rem !important;
}
.html_13 .first .zp p,
.html_13 .first .zp strong {
  font-size: 0.37333333rem !important;
}
.html_13 .first .zp .content {
  font-size: 0.37333333rem !important;
}
.html_13 .first .content {
  padding: 0!important;
}
.html_13 .canvas {
  width: 9.2rem;
  position: relative;
}
.html_13 .canvas .ring-content {
  border: .02rem solid #f1f1f1;
  padding: .4rem;
  margin: .4rem 0;
  position: relative;
}
.html_13 .canvas .ring-defen {
  position: absolute;
  z-index: 100;
  text-align: center;
  top: 50%;
  margin-top: -0.6rem;
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 1.33333333rem;
  color: #fd622e;
  width: 100%;
  left: 0;
}
.html_13 .canvas .ring-title {
  width: 100%;
  font-size: 0.42666667rem;
  color: #333;
  text-align: center;
}
.html_13 .canvas .ring-shuoming {
  width: 100%;
  font-size: 0.37333333rem;
  color: #666;
  text-align: center;
}
.html_13 .canvas .ring-canvas {
  height: 300px;
  width: 8.4rem;
  position: relative;
}
.html_13 .canvas span {
  display: block;
  font-size: 0.42666667rem;
  font-weight: bold;
  color: #3b3bd2;
  position: absolute;
}
.html_13 .canvas span._1 {
  top: 3.4rem;
  left: .2rem;
}
.html_13 .canvas span._2 {
  top: .8rem;
  left: 1.8rem;
}
.html_13 .canvas span._3 {
  top: .8rem;
  right: 1.8rem;
}
.html_13 .canvas span._4 {
  top: 3.4rem;
  right: .2rem;
}
.html_13 .canvas .vebar {
  margin-top: -0.4rem;
}
.html_13 .canvas p {
  font-size: 0.37333333rem;
  color: #666;
  padding: .4rem;
  margin-top: -1rem;
  line-height: .6rem;
}
.html_13 .content {
  padding: .4rem 0;
}
.html_13 .content p,
.html_13 .content section,
.html_13 .content strong {
  font-size: 0.37333333rem !important;
}
.html_13 .bar_canvas {
  display: flex;
  justify-content: space-around;
  margin: .6rem 0;
}
.html_13 .bar_canvas .item {
  position: relative;
  width: 1rem;
  height: 5.2rem;
  background: #f5f5f5;
  border-radius: .08rem;
  margin-bottom: .7rem;
}
.html_13 .bar_canvas .item .c {
  position: absolute;
  left: .1rem;
  bottom: 0;
  width: .8rem;
  background: #ffcdba;
  border-radius: .08rem;
  text-align: center;
  color: #FFF;
  box-sizing: border-box;
  padding-top: .1rem;
}
.html_13 .bar_canvas .item .c span {
  color: #FFF;
}
.html_13 .bar_canvas .item .txt {
  color: #333;
  font-size: 0.37333333rem;
  position: absolute;
  bottom: -0.7rem;
  width: 2rem;
  left: -0.5rem;
  text-align: center;
}
.html_13 .table-loop .hdd {
  position: relative;
  display: flex;
  justify-content: center;
  padding: .4rem 0;
}
.html_13 .table-loop .hdd i {
  color: #3b3bd2;
  font-size: 0.53333333rem;
}
.html_13 .table-loop .hdd span {
  color: #3b3bd2;
  font-size: 0.53333333rem;
  font-weight: 600;
  margin: 0 .2rem;
}
.html_13 .table-loop .table {
  margin: .2rem .4rem .8rem;
  border-radius: .26rem;
  overflow: hidden;
}
.html_13 .table-loop .table-item {
  display: flex;
  background: #ffcdba;
}
.html_13 .table-loop .table-item.title {
  background: #ffcdba;
}
.html_13 .table-loop .table-item.title span {
  color: #fd622e!important;
}
.html_13 .table-loop .table-item:nth-child(2n) {
  background: #ffd8ca;
}
.html_13 .table-loop .table-item span {
  flex: 1;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.37333333rem;
  color: #fd622e;
  text-align: center;
}
.html_13 section img {
  max-width: 100%!important;
  margin: 0 auto!important;
  display: block!important;
}
.html_13 .line {
  text-align: center;
  font-size: 0.32rem;
}
.html_13 .line em {
  display: inline-block;
  padding-left: .56rem;
  color: #666;
  position: relative;
  height: .6rem;
  line-height: .6rem;
  margin: 0 .3rem;
}
.html_13 .line em.fs:before {
  content: '';
  display: block;
  height: .3rem;
  width: .5rem;
  background: #5ec2b5;
  position: absolute;
  left: 0;
  top: .15rem;
}
.html_13 .line em.fs2:before {
  content: '';
  display: block;
  height: .3rem;
  width: .5rem;
  background: #fdff75;
  position: absolute;
  left: 0;
  top: .15rem;
}
.html_13 .line em.pj:before {
  content: '';
  display: block;
  height: .3rem;
  width: .5rem;
  background: #d7eae8;
  position: absolute;
  left: 0;
  top: .15rem;
}
.html_13 .table {
  margin: .2rem 0 .8rem;
}
.html_13 .table-item {
  display: flex;
  background: #e3fdf9;
}
.html_13 .table-item.title {
  background: #2e2e2e;
}
.html_13 .table-item.title span {
  color: #c6f0e9!important;
}
.html_13 .table-item:nth-child(2n) {
  background: #cdf0eb;
}
.html_13 .table-item span {
  flex: 1;
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.37333333rem;
  color: #333;
  text-align: center;
}
.html_13 .limit-box__switch {
  display: none!important;
}
