.page                               {
    &-result                        {background: #FFF;padding: .4rem;
        .hd                         {height: 1.2rem;background: url(../images/report_subnav.png) no-repeat left center / 100%;line-height: 1.2rem;box-sizing: border-box;padding-left: .8rem;font-weight: 600;.fs(16);color: #424242;margin-bottom: .4rem;}
        .name                       {.fs(16);color: #424242;font-weight: 600;margin: .4rem 0 .2rem;}
        .name1                      {.fs(14);color: #666;}
        .content                    {.fs(14);color: #666;line-height: .66rem;
            p                       {.fs(14)!important;}
        }
        .line                       {text-align: center;.fs(12);
            em                      {display: inline-block;padding-left: .56rem;color:#666;position: relative;height: .6rem;line-height: .6rem;margin: 0 .3rem;
                &.fs:before         {content: '';display: block;height: .3rem;width: .5rem;background: #5ec2b5;position: absolute;left: 0;top: .15rem;}
                &.pj:before         {content: '';display: block;height: .3rem;width: .5rem;background: #d7eae8;position: absolute;left: 0;top: .15rem;}
            }
        }
        .zongfen                    {.fs(14);color: #999;text-align: center;margin: .2rem 0 .4rem;height: .66rem;line-height: .66rem;
            span                    {color: #5ec2b5;font-weight: 600;.fs(16);}
        }  

        // bar
        .bar                        {margin-top: .4rem;
            .box                    {margin: -.4rem 0 0;padding: .4rem;box-sizing: border-box;border: .02rem solid #eaeaea;
                .title              {color: #666;.fs(16);margin-bottom: .4rem;text-align: center;}
                .bar_canvas         {display: flex;justify-content: space-around;margin-top: .6rem;}
                .item               {position: relative;width: 1rem;height: 6.2rem;background: #f5f5f5;border-radius: .08rem;margin-bottom: .7rem;
                    .c              {position: absolute;left: .1rem;bottom: 0;width: .8rem;background: #5ec2b5;border-radius: .08rem;text-align: center;color: #FFF;
                        span        {color: #FFF;}
                    }
                    .txt            {color: #333;.fs(14);position: absolute;bottom: -.7rem;width: 2rem;left: -.5rem;text-align: center;}
                }
                .desc               {color: #666;margin-top: .4rem;}
            }
        }

        // bar
        .loop                       {margin-top: .4rem;padding-bottom: .8rem;background: #FFF;
            .title-hd               {position: relative;display: inline-block;
                span                {background: -webkit-linear-gradient(right, rgb(255, 100, 103), rgb(255, 174, 175));padding: 0 .4rem;display: block;line-height: 1.2rem;margin: 0 auto;color: #FFF;.fs(20);font-weight: 600;margin-bottom: -.6rem;position: relative;z-index: 1;letter-spacing: .1rem;margin: 0 auto;}
                &:before            {content: '';display: block;position: absolute;top: -.08rem;left: -.08rem;width: 100%;height: 1.2rem;border: .02rem solid rgb(255, 100, 103);z-index: 1;}
            }
            .box                    {margin: .4rem 0;padding: .4rem;border: .02rem solid #eaeaea;
                .title              {color: #666;.fs(16);margin-bottom: .4rem;text-align: center;}
                .bar_canvas         {}
                .item               {position: relative;width: 1rem;height: 6.2rem;background: #f5f5f5;border-radius: .08rem;margin-bottom: .7rem;
                    .c              {position: absolute;left: .1rem;bottom: 0;width: .8rem;background: #5ec2b5;border-radius: .08rem;text-align: center;color: #FFF;
                        span        {color: #FFF;}
                    }
                    .txt            {color: #333;.fs(14);position: absolute;bottom: -.7rem;width: 2rem;left: -.5rem;text-align: center;}
                }
                .desc               {color: #666;margin-top: .4rem;}
            }
        }


        .canvas                 {width: 9.2rem;position: relative;
            .ring-content       {border: .02rem solid #f1f1f1;padding: .4rem;margin: .4rem 0;position: relative;}
            .ring-defen         {position: absolute;z-index: 100;text-align: center;top: 50%;margin-top: -.6rem;height: 1.2rem;line-height: 1.2rem;.fs(50);color: #5ec2b5;width: 100%;left: 0;}
            .ring-title         {width: 100%;.fs(16);color: #333;text-align: center;}
            .ring-shuoming      {width: 100%;.fs(14);color: #666;text-align: center;}
            .ring-canvas        {height: 300px;width: 8.4rem;position: relative;}
            span                {display: block;.fs(16);font-weight: bold;color: #5ec2b5;position: absolute;
                &._1            {top: 3rem;left: 0;}
                &._2            {top: .6rem;left: 1.6rem;}
                &._3            {top: .6rem;right: 1.6rem;}
                &._4            {top: 3rem;right:0;}
            }
            .vebar              {margin-top: -.4rem;}
            p                   {.fs(14);color: #666;padding: .4rem;margin-top: -1rem;line-height: .6rem;}
        }
    }
}