.page-result {
  background: #FFF;
  padding: .4rem;
}
.page-result .hd {
  height: 1.2rem;
  background: url(../images/report_subnav.png) no-repeat left center / 100%;
  line-height: 1.2rem;
  box-sizing: border-box;
  padding-left: .8rem;
  font-weight: 600;
  font-size: 0.42666667rem;
  color: #424242;
  margin-bottom: .4rem;
}
.page-result .name {
  font-size: 0.42666667rem;
  color: #424242;
  font-weight: 600;
  margin: .4rem 0 .2rem;
}
.page-result .name1 {
  font-size: 0.37333333rem;
  color: #666;
}
.page-result .content {
  font-size: 0.37333333rem;
  color: #666;
  line-height: .66rem;
}
.page-result .content p {
  font-size: 0.37333333rem !important;
}
.page-result .line {
  text-align: center;
  font-size: 0.32rem;
}
.page-result .line em {
  display: inline-block;
  padding-left: .56rem;
  color: #666;
  position: relative;
  height: .6rem;
  line-height: .6rem;
  margin: 0 .3rem;
}
.page-result .line em.fs:before {
  content: '';
  display: block;
  height: .3rem;
  width: .5rem;
  background: #5ec2b5;
  position: absolute;
  left: 0;
  top: .15rem;
}
.page-result .line em.pj:before {
  content: '';
  display: block;
  height: .3rem;
  width: .5rem;
  background: #d7eae8;
  position: absolute;
  left: 0;
  top: .15rem;
}
.page-result .zongfen {
  font-size: 0.37333333rem;
  color: #999;
  text-align: center;
  margin: .2rem 0 .4rem;
  height: .66rem;
  line-height: .66rem;
}
.page-result .zongfen span {
  color: #5ec2b5;
  font-weight: 600;
  font-size: 0.42666667rem;
}
.page-result .bar {
  margin-top: .4rem;
}
.page-result .bar .box {
  margin: -0.4rem 0 0;
  padding: .4rem;
  box-sizing: border-box;
  border: .02rem solid #eaeaea;
}
.page-result .bar .box .title {
  color: #666;
  font-size: 0.42666667rem;
  margin-bottom: .4rem;
  text-align: center;
}
.page-result .bar .box .bar_canvas {
  display: flex;
  justify-content: space-around;
  margin-top: .6rem;
}
.page-result .bar .box .item {
  position: relative;
  width: 1rem;
  height: 6.2rem;
  background: #f5f5f5;
  border-radius: .08rem;
  margin-bottom: .7rem;
}
.page-result .bar .box .item .c {
  position: absolute;
  left: .1rem;
  bottom: 0;
  width: .8rem;
  background: #5ec2b5;
  border-radius: .08rem;
  text-align: center;
  color: #FFF;
}
.page-result .bar .box .item .c span {
  color: #FFF;
}
.page-result .bar .box .item .txt {
  color: #333;
  font-size: 0.37333333rem;
  position: absolute;
  bottom: -0.7rem;
  width: 2rem;
  left: -0.5rem;
  text-align: center;
}
.page-result .bar .box .desc {
  color: #666;
  margin-top: .4rem;
}
.page-result .loop {
  margin-top: .4rem;
  padding-bottom: .8rem;
  background: #FFF;
}
.page-result .loop .title-hd {
  position: relative;
  display: inline-block;
}
.page-result .loop .title-hd span {
  background: -webkit-linear-gradient(right, #ff6467, #ffaeaf);
  padding: 0 .4rem;
  display: block;
  line-height: 1.2rem;
  color: #FFF;
  font-size: 0.53333333rem;
  font-weight: 600;
  margin-bottom: -0.6rem;
  position: relative;
  z-index: 1;
  letter-spacing: .1rem;
  margin: 0 auto;
}
.page-result .loop .title-hd:before {
  content: '';
  display: block;
  position: absolute;
  top: -0.08rem;
  left: -0.08rem;
  width: 100%;
  height: 1.2rem;
  border: 0.02rem solid #ff6467;
  z-index: 1;
}
.page-result .loop .box {
  margin: .4rem 0;
  padding: .4rem;
  border: .02rem solid #eaeaea;
}
.page-result .loop .box .title {
  color: #666;
  font-size: 0.42666667rem;
  margin-bottom: .4rem;
  text-align: center;
}
.page-result .loop .box .item {
  position: relative;
  width: 1rem;
  height: 6.2rem;
  background: #f5f5f5;
  border-radius: .08rem;
  margin-bottom: .7rem;
}
.page-result .loop .box .item .c {
  position: absolute;
  left: .1rem;
  bottom: 0;
  width: .8rem;
  background: #5ec2b5;
  border-radius: .08rem;
  text-align: center;
  color: #FFF;
}
.page-result .loop .box .item .c span {
  color: #FFF;
}
.page-result .loop .box .item .txt {
  color: #333;
  font-size: 0.37333333rem;
  position: absolute;
  bottom: -0.7rem;
  width: 2rem;
  left: -0.5rem;
  text-align: center;
}
.page-result .loop .box .desc {
  color: #666;
  margin-top: .4rem;
}
.page-result .canvas {
  width: 9.2rem;
  position: relative;
}
.page-result .canvas .ring-content {
  border: .02rem solid #f1f1f1;
  padding: .4rem;
  margin: .4rem 0;
  position: relative;
}
.page-result .canvas .ring-defen {
  position: absolute;
  z-index: 100;
  text-align: center;
  top: 50%;
  margin-top: -0.6rem;
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 1.33333333rem;
  color: #5ec2b5;
  width: 100%;
  left: 0;
}
.page-result .canvas .ring-title {
  width: 100%;
  font-size: 0.42666667rem;
  color: #333;
  text-align: center;
}
.page-result .canvas .ring-shuoming {
  width: 100%;
  font-size: 0.37333333rem;
  color: #666;
  text-align: center;
}
.page-result .canvas .ring-canvas {
  height: 300px;
  width: 8.4rem;
  position: relative;
}
.page-result .canvas span {
  display: block;
  font-size: 0.42666667rem;
  font-weight: bold;
  color: #5ec2b5;
  position: absolute;
}
.page-result .canvas span._1 {
  top: 3rem;
  left: 0;
}
.page-result .canvas span._2 {
  top: .6rem;
  left: 1.6rem;
}
.page-result .canvas span._3 {
  top: .6rem;
  right: 1.6rem;
}
.page-result .canvas span._4 {
  top: 3rem;
  right: 0;
}
.page-result .canvas .vebar {
  margin-top: -0.4rem;
}
.page-result .canvas p {
  font-size: 0.37333333rem;
  color: #666;
  padding: .4rem;
  margin-top: -1rem;
  line-height: .6rem;
}
