.page                               {
    &-result                        {background: #FFF;margin-top: .4rem;padding: .8rem .4rem;
        .hd                         {margin-bottom: .8rem;
            span                    {display: inline-block;padding: 0 .2rem;.fs(20);}
            i                       {color: #5ec2b5;font-size: .8rem;position: relative;
                &.icon2             {top: .5rem;}
            }
        }
        .loop                       {margin: 1rem 0;
            .canvas                 {margin: .4rem 0;border: .02rem solid #eaeaea;position: relative;width: 9.2rem;
                .desc               {.fs(14);color: #333;padding: .4rem;line-height: .6rem;position: absolute;font-weight: 600;}
                .vebar              {margin-top: 0;}
                p                   {.fs(14);color: #666;padding: .4rem;margin-top: -1rem;line-height: .6rem;}
            }
        }
    }
}