.ellipsis                           {overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
.report                             {
    &-banner                        {position: relative;overflow: hidden;
        &:before                    {content: '';display: block;padding-bottom: 60%;}
        &:after                     {content: '';display: block;position: absolute;top: 0;left: 0;width: 100%;height: 100%;background: #000;opacity: .5;z-index: 1;}
        > img                       {position: absolute;width: 100%;height: 100%;top: 0;left: 0;}
        .user                       {position: absolute;top: 0;left: 0;width: 100%;box-sizing: border-box;padding: .4rem .6rem;display: flex;z-index: 2;
            .pic                    {width: 1.1rem;height: 1.1rem;border-radius: 50%;position: relative;overflow: hidden;border: .06rem solid #FFF;z-index: 10;
                img                 {position: absolute;top: 50%;left: 50%;transform: translate3d(-50%, -50%, 0);width: 100%;}
            }
            .nickname               {display: flex;flex: 1;overflow: hidden;color: #FFF;height: 1.2rem;margin-left: .4rem;flex-direction: column;justify-content: center;
                span                {
                    &:nth-child(1)  {.fs(18);}
                    &:nth-child(2)  {.fs(14);margin-top: .04rem;}
                }
            }
        }
        .title                      {height: 1.4rem;margin-top: -.7rem;display: flex;align-items: center;position: absolute;top: 50%;left: 0;box-sizing: border-box;padding: 0 .6rem;justify-content: center;.fs(20);color: #FFF;z-index: 2;width: 100%;}
    }
    &-desc                          {background: #FFF;padding: .4rem .4rem 1.4rem .4rem;
        .hd                         {.fs(18);color: #333;margin-bottom: .2rem;font-weight: 600;}
        .content                    {.fs(14);color: #666;line-height: .66rem;}
    }
    
    &-tpl9                          {padding: 1.2rem 1.2rem 1.6rem; background: #FFF;position: relative;line-height: .7rem!important;.fs(16);color: #888;
        h3                          {.fs(18);color: #424242;margin-bottom: .2rem;font-weight: 600;display: block;text-align: center;}
        &:before,
        &:after                     {content: '';display: block;width: 9rem;height: 2.4rem;position: absolute;left: .4rem;}
        &:before                    {top: .4rem;background: url(./images/report_border_top.png) no-repeat top center / 100%;}
        &:after                     {bottom: .4rem;background: url(./images/report_border_bottom.png) no-repeat top center / 100%;}
    }

    &-tpl11                         {
        .content                    {background: #FFF;padding: .6rem .6rem;}
        h3                          {background: -webkit-linear-gradient(right, rgb(255, 100, 103), rgb(255, 174, 175));height: 1.2rem;width: 5.4rem;text-align: center;line-height: 1.2rem;border-radius: 1rem;margin: 0 auto;color: #FFF;.fs(20);font-weight: 600;margin-bottom: -.6rem;position: relative;z-index: 1;letter-spacing: .1rem;}
        .shuoming                   {box-shadow: 0 0 .16rem rgba(0,0,0,0.2);padding: 1.2rem .6rem .6rem;border-radius: .16rem;.fs(16);color: #333;
            p                       {margin-bottom: .2rem;}
        }
    }

    &-tpl12                         {
        .bg                         {position: absolute;top: 0;left: 50%;height: 6rem;width: 10rem;margin-left: -5rem;overflow: hidden;
            .c                      {position: relative;height: 100%;width: 100%;
                &:before            {content: '';display: block;width: 14rem;height: 6rem;background: #05114c;position: absolute;top: -2rem;left: -2rem;transform: rotate(14deg);z-index: 10;}
            }
        }
        .content                    {padding: .6rem .6rem;position: relative;height: 6rem;overflow-x: hidden;z-index: 11;}
        h3                          {position: relative;z-index: 1;color: #FFF;.fs(20);}
    }

    &-line                          {position: relative;padding: 1.4rem 0 .2rem;background: #FFF;
        &:before                    {content: '';display: block;height: .03rem;width: 3.8rem;position: absolute;left: .8rem;top: .8rem;background: #5ec2b5;opacity: .4;z-index: 1;}
        &:after                     {content: '';display: block;height: .03rem;width: 3.8rem;position: absolute;right: .8rem;top: .8rem;background: #5ec2b5;opacity: .4;z-index: 1;}
        .bg                         {position: absolute;width: 1rem;height: .03rem;background: none;position: absolute;left: 50%;margin-left: -.5rem;top: .8rem;z-index: 2;
            &:after                 {content: '';display: block;width: .1rem;height: .1rem;background: #5ec2b5;opacity: .4;left: 50%;top: 0rem;margin-left: -.07rem;position: absolute;z-index: 3;border-radius: 50%;}
        }
        .end                        {text-align: center;color: #5ec2b5;opacity: .6;.fs(14);letter-spacing: .06rem;}
    }

    &-control                       {position: fixed;bottom: 0;left: 0;width: 100%;z-index: 100;
        .content                    {width: 10rem;margin: 0 auto;display: flex;background: #FFF;height: 1.44rem;box-sizing:border-box;padding: .14rem .2rem;border-top: .02rem solid #f3f3f3;
            .btn                    {display: flex;width: 1.16rem;height: 1.16rem;flex-direction: column;.fs(12);color: #333;align-items: center;justify-content: center;
                i                   {.fs(26);height: .7rem;}
            }
            .submit                 {display: block;height: 1.16rem;color: #FFF;.fs(14);background: #5ec2b5;flex: 1;border-radius: .14rem;margin-left: .2rem;.fs(16);font-weight: 600;text-align: center;
                i                   {.fs(12);line-height: 1.16rem;}
                span                {.fs(16);margin: 0 .08rem;line-height: 1.16rem;}
            }
        }
    }
}

// 分享引导弹窗
.share                  {position: fixed;top: 0;right: 0;width: 6rem;
    &-img               {width: 100%;}
    &-btn               {width: 60%;margin: .5rem 0 0 20%;}

}

// 二维码弹窗
.ticket                 {display: flex;align-items: center;justify-content: center;height: 100%;
    .content            {width: 7.8rem;padding: .4rem 0;border-radius: .16rem;background: #FFF;display: flex;flex-direction: column;align-items: center;margin-top: -2rem;
        p               {.fs(18);text-align: center;font-weight: 600;padding-top: .4rem;}
        .img            {width: 4.6rem;display: block;padding: .2rem;box-sizing: border-box;border: .1rem solid #5ec2b5;margin: 1rem 0 .4rem;border-radius: .4rem;}
        .d              {margin-top: .4rem;.fs(16);color: #5ec2b5;display: block;text-align: center;padding-bottom: .4rem;}
    }
}