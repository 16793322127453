.html_13                    {
    .first                  {
        .zp                 {padding: 0 .4rem;background: #fafafa;.fs(14)!important;
            p,
            strong          {.fs(14)!important}
            .content        {.fs(14)!important;}
        }
        .content            {padding: 0!important;}
    }
    .canvas                 {width: 9.2rem;position: relative;
        .ring-content       {border: .02rem solid #f1f1f1;padding: .4rem;margin: .4rem 0;position: relative;}
        .ring-defen         {position: absolute;z-index: 100;text-align: center;top: 50%;margin-top: -.6rem;height: 1.2rem;line-height: 1.2rem;.fs(50);color: #fd622e;width: 100%;left: 0;}
        .ring-title         {width: 100%;.fs(16);color: #333;text-align: center;}
        .ring-shuoming      {width: 100%;.fs(14);color: #666;text-align: center;}
        .ring-canvas        {height: 300px;width: 8.4rem;position: relative;}
        span                {display: block;.fs(16);font-weight: bold;color: #3b3bd2;position: absolute;
            &._1            {top: 3.4rem;left: .2rem;}
            &._2            {top: .8rem;left: 1.8rem;}
            &._3            {top: .8rem;right: 1.8rem;}
            &._4            {top: 3.4rem;right: .2rem;}
        }
        .vebar              {margin-top: -.4rem;}
        p                   {.fs(14);color: #666;padding: .4rem;margin-top: -1rem;line-height: .6rem;}
    }
    .content                {padding: .4rem 0;
        p,
        section,
        strong              {.fs(14)!important}
    }
    .bar_canvas                 {display: flex;justify-content: space-around;margin: .6rem 0;
        .item                   {position: relative;width: 1rem;height: 5.2rem;background: #f5f5f5;border-radius: .08rem;margin-bottom: .7rem;
            .c                  {position: absolute;left: .1rem;bottom: 0;width: .8rem;background: #ffcdba;border-radius: .08rem;text-align: center;color: #FFF;box-sizing: border-box;padding-top: .1rem;
                span            {color: #FFF;}
            }
            .txt                {color: #333;.fs(14);position: absolute;bottom: -.7rem;width: 2rem;left: -.5rem;text-align: center;}
        }
    }

    .table-loop                     {
        .hdd                        {position: relative;display: flex;justify-content: center;padding: .4rem 0;
            i                       {color: #3b3bd2;.fs(20);}
            span                    {color: #3b3bd2;.fs(20);font-weight: 600;margin: 0 .2rem;}
        }
        .table                      {margin: .2rem .4rem .8rem;border-radius: .26rem;overflow: hidden;
            &-item                  {display: flex;background: #ffcdba;
                &.title             {background: #ffcdba;
                    span            {color: #fd622e!important;}
                }
                &:nth-child(2n)     {background: #ffd8ca;}
                span                {flex: 1;height: 1rem;line-height: 1rem;.fs(14);color: #fd622e;text-align: center;}
            }
        }
    }
    section                         {
        img                         {max-width: 100%!important;margin: 0 auto!important;display: block!important;}
    }
    .line                       {text-align: center;.fs(12);
        em                      {display: inline-block;padding-left: .56rem;color:#666;position: relative;height: .6rem;line-height: .6rem;margin: 0 .3rem;
            &.fs:before         {content: '';display: block;height: .3rem;width: .5rem;background: #5ec2b5;position: absolute;left: 0;top: .15rem;}
            &.fs2:before        {content: '';display: block;height: .3rem;width: .5rem;background: #fdff75;position: absolute;left: 0;top: .15rem;}
            &.pj:before         {content: '';display: block;height: .3rem;width: .5rem;background: #d7eae8;position: absolute;left: 0;top: .15rem;}
        }
    }
    .table                      {margin: .2rem 0 .8rem;
        &-item                  {display: flex;background: #e3fdf9;
            &.title             {background: rgb(46, 46, 46);
                span            {color: #c6f0e9!important;}
            }
            &:nth-child(2n)     {background: #cdf0eb;}
            span                {flex: 1;height: 1.2rem;line-height: 1.2rem;.fs(14);color: #333;text-align: center;}
        }
    }
    .limit-box__switch          {display: none!important;}
}