.page                               {
    &-result                        {background: #FFF;margin-top: .4rem;padding: .8rem .4rem 0;
        .hd                         {.fs(18);color: #333;margin-bottom: .2rem;font-weight: 600;text-align: center;height: 1.2rem;line-height: 1.2rem;}
        .content                    {.fs(14);color: #666;line-height: .66rem;
            p                       {.fs(14)!important;}
        }
        .line                       {text-align: center;.fs(12);
            em                      {display: inline-block;padding-left: .56rem;color:#666;position: relative;height: .6rem;line-height: .6rem;margin: 0 .3rem;
                &.fs:before         {content: '';display: block;height: .3rem;width: .5rem;background: #5ec2b5;position: absolute;left: 0;top: .15rem;}
                &.fs2:before        {content: '';display: block;height: .3rem;width: .5rem;background: #fdff75;position: absolute;left: 0;top: .15rem;}
                &.pj:before         {content: '';display: block;height: .3rem;width: .5rem;background: #d7eae8;position: absolute;left: 0;top: .15rem;}
            }
        }
        .zongfen                    {.fs(14);color: #999;text-align: center;margin: .2rem 0 .4rem;height: .66rem;line-height: .66rem;
            span                    {color: #5ec2b5;font-weight: 600;.fs(16);}
        }  
        .table                      {margin: .2rem 0 .8rem;
            &-item                  {display: flex;background: #e3fdf9;
                &.title             {background: rgb(46, 46, 46);
                    span            {color: #c6f0e9!important;}
                }
                &:nth-child(2n)     {background: #cdf0eb;}
                span                {flex: 1;height: 1.2rem;line-height: 1.2rem;.fs(14);color: #333;text-align: center;}
            }
        }
        .canvas                 {width: 8.4rem;margin: .4rem auto;position: relative;
            span                {display: block;.fs(16);font-weight: bold;color: #5ec2b5;position: absolute;
                &._1            {top: 3rem;left: 0;}
                &._2            {top: .6rem;left: 1.6rem;}
                &._3            {top: .6rem;right: 1.6rem;}
                &._4            {top: 3rem;right:0;}
            }
            .vebar              {margin-top: -.4rem;}
            p                   {.fs(14);color: #666;padding: .4rem;margin-top: -1rem;line-height: .6rem;}
        }

        .bar_canvas                 {display: flex;justify-content: space-around;margin: .6rem 0;
            .item                   {position: relative;width: 1rem;height: 5.2rem;background: #f5f5f5;border-radius: .08rem;margin-bottom: .7rem;
                .c                  {position: absolute;left: .1rem;bottom: 0;width: .8rem;background: #c6f0e9;border-radius: .08rem;text-align: center;color: #FFF;box-sizing: border-box;padding-top: .1rem;
                    span            {color: #FFF;}
                }
                .txt                {color: #333;.fs(14);position: absolute;bottom: -.7rem;width: 2rem;left: -.5rem;text-align: center;}
            }
        }
    }
}