.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recommend {
  background: #FFF;
  padding-bottom: .4rem;
}
.recommend .hd {
  display: flex;
  align-items: center;
  padding: .7rem .4rem .4rem;
  width: 100%;
  justify-content: space-between;
}
.recommend .hd span {
  font-size: 0.42666667rem;
  font-weight: 600;
  color: #333;
}
.recommend .hd a {
  font-size: 0.32rem;
  color: #a0a4a3;
}
.recommend .loop {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 .28rem;
}
.recommend .item {
  padding: 0 .12rem;
  width: 50%;
  box-sizing: border-box;
}
.recommend a {
  display: flex;
  flex-direction: column;
}
.recommend a .pic {
  position: relative;
  overflow: hidden;
  border-radius: .14rem .14rem 0 0;
}
.recommend a .pic:before {
  content: '';
  display: block;
  padding-bottom: 2.4rem;
}
.recommend a .pic img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
}
.recommend a .info {
  border-top: none;
  padding: .22rem .1rem .1rem;
}
.recommend a .info .title {
  font-size: 0.37333333rem;
  font-weight: 600;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recommend a .info .hits {
  font-size: 0.32rem;
  color: #a0a4a3;
}
