.page                               {
    &-result                        {margin: 0;
        .hd                         {height: 1.2rem;background: url(../images/report_subnav.png) no-repeat left center / 100%;line-height: 1.2rem;box-sizing: border-box;padding-left: .8rem;font-weight: 600;.fs(16);color: #424242;margin-bottom: 1rem;}
        .box                        {margin: .4rem 0 .8rem;border: .02rem solid #eaeaea;padding: .4rem;box-sizing: border-box;
            .title                  {color: #666;.fs(16);margin-bottom: .4rem;text-align: center;}
            .bar_canvas             {display: flex;justify-content: space-around;margin-top: .6rem;}
            .item                   {position: relative;width: 1rem;height: 6.2rem;background: #f5f5f5;border-radius: .08rem;margin-bottom: .7rem;
                .c                  {position: absolute;left: .1rem;bottom: 0;width: .8rem;background: #5ec2b5;border-radius: .08rem;text-align: center;color: #FFF;
                    span            {color: #FFF;}
                }
                .txt                {color: #333;.fs(14);position: absolute;bottom: -.7rem;width: 2rem;left: -.5rem;text-align: center;}
            }
            .desc                   {color: #666;margin-top: .4rem;}
        }
    }
}