.fs(@font-size) {
    font-size: @font-size / 37.5rem;
}

@info: #cdac7a;





























@import '../tpl.less';
