.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.report-banner {
  position: relative;
  overflow: hidden;
}
.report-banner:before {
  content: '';
  display: block;
  padding-bottom: 60%;
}
.report-banner:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .5;
  z-index: 1;
}
.report-banner > img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.report-banner .user {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: .4rem .6rem;
  display: flex;
  z-index: 2;
}
.report-banner .user .pic {
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: .06rem solid #FFF;
  z-index: 10;
}
.report-banner .user .pic img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
}
.report-banner .user .nickname {
  display: flex;
  flex: 1;
  overflow: hidden;
  color: #FFF;
  height: 1.2rem;
  margin-left: .4rem;
  flex-direction: column;
  justify-content: center;
}
.report-banner .user .nickname span:nth-child(1) {
  font-size: 0.48rem;
}
.report-banner .user .nickname span:nth-child(2) {
  font-size: 0.37333333rem;
  margin-top: .04rem;
}
.report-banner .title {
  height: 1.4rem;
  margin-top: -0.7rem;
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 0;
  box-sizing: border-box;
  padding: 0 .6rem;
  justify-content: center;
  font-size: 0.53333333rem;
  color: #FFF;
  z-index: 2;
  width: 100%;
}
.report-desc {
  background: #FFF;
  padding: .4rem .4rem 1.4rem .4rem;
}
.report-desc .hd {
  font-size: 0.48rem;
  color: #333;
  margin-bottom: .2rem;
  font-weight: 600;
}
.report-desc .content {
  font-size: 0.37333333rem;
  color: #666;
  line-height: .66rem;
}
.report-tpl9 {
  padding: 1.2rem 1.2rem 1.6rem;
  background: #FFF;
  position: relative;
  line-height: .7rem!important;
  font-size: 0.42666667rem;
  color: #888;
}
.report-tpl9 h3 {
  font-size: 0.48rem;
  color: #424242;
  margin-bottom: .2rem;
  font-weight: 600;
  display: block;
  text-align: center;
}
.report-tpl9:before,
.report-tpl9:after {
  content: '';
  display: block;
  width: 9rem;
  height: 2.4rem;
  position: absolute;
  left: .4rem;
}
.report-tpl9:before {
  top: .4rem;
  background: url(images/report_border_top.png) no-repeat top center / 100%;
}
.report-tpl9:after {
  bottom: .4rem;
  background: url(images/report_border_bottom.png) no-repeat top center / 100%;
}
.report-tpl11 .content {
  background: #FFF;
  padding: .6rem .6rem;
}
.report-tpl11 h3 {
  background: -webkit-linear-gradient(right, #ff6467, #ffaeaf);
  height: 1.2rem;
  width: 5.4rem;
  text-align: center;
  line-height: 1.2rem;
  border-radius: 1rem;
  margin: 0 auto;
  color: #FFF;
  font-size: 0.53333333rem;
  font-weight: 600;
  margin-bottom: -0.6rem;
  position: relative;
  z-index: 1;
  letter-spacing: .1rem;
}
.report-tpl11 .shuoming {
  box-shadow: 0 0 0.16rem rgba(0, 0, 0, 0.2);
  padding: 1.2rem .6rem .6rem;
  border-radius: .16rem;
  font-size: 0.42666667rem;
  color: #333;
}
.report-tpl11 .shuoming p {
  margin-bottom: .2rem;
}
.report-tpl12 .bg {
  position: absolute;
  top: 0;
  left: 50%;
  height: 6rem;
  width: 10rem;
  margin-left: -5rem;
  overflow: hidden;
}
.report-tpl12 .bg .c {
  position: relative;
  height: 100%;
  width: 100%;
}
.report-tpl12 .bg .c:before {
  content: '';
  display: block;
  width: 14rem;
  height: 6rem;
  background: #05114c;
  position: absolute;
  top: -2rem;
  left: -2rem;
  transform: rotate(14deg);
  z-index: 10;
}
.report-tpl12 .content {
  padding: .6rem .6rem;
  position: relative;
  height: 6rem;
  overflow-x: hidden;
  z-index: 11;
}
.report-tpl12 h3 {
  position: relative;
  z-index: 1;
  color: #FFF;
  font-size: 0.53333333rem;
}
.report-line {
  position: relative;
  padding: 1.4rem 0 .2rem;
  background: #FFF;
}
.report-line:before {
  content: '';
  display: block;
  height: .03rem;
  width: 3.8rem;
  position: absolute;
  left: .8rem;
  top: .8rem;
  background: #5ec2b5;
  opacity: .4;
  z-index: 1;
}
.report-line:after {
  content: '';
  display: block;
  height: .03rem;
  width: 3.8rem;
  position: absolute;
  right: .8rem;
  top: .8rem;
  background: #5ec2b5;
  opacity: .4;
  z-index: 1;
}
.report-line .bg {
  width: 1rem;
  height: .03rem;
  background: none;
  position: absolute;
  left: 50%;
  margin-left: -0.5rem;
  top: .8rem;
  z-index: 2;
}
.report-line .bg:after {
  content: '';
  display: block;
  width: .1rem;
  height: .1rem;
  background: #5ec2b5;
  opacity: .4;
  left: 50%;
  top: 0rem;
  margin-left: -0.07rem;
  position: absolute;
  z-index: 3;
  border-radius: 50%;
}
.report-line .end {
  text-align: center;
  color: #5ec2b5;
  opacity: .6;
  font-size: 0.37333333rem;
  letter-spacing: .06rem;
}
.report-control {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
.report-control .content {
  width: 10rem;
  margin: 0 auto;
  display: flex;
  background: #FFF;
  height: 1.44rem;
  box-sizing: border-box;
  padding: .14rem .2rem;
  border-top: .02rem solid #f3f3f3;
}
.report-control .content .btn {
  display: flex;
  width: 1.16rem;
  height: 1.16rem;
  flex-direction: column;
  font-size: 0.32rem;
  color: #333;
  align-items: center;
  justify-content: center;
}
.report-control .content .btn i {
  font-size: 0.69333333rem;
  height: .7rem;
}
.report-control .content .submit {
  display: block;
  height: 1.16rem;
  color: #FFF;
  font-size: 0.37333333rem;
  background: #5ec2b5;
  flex: 1;
  border-radius: .14rem;
  margin-left: .2rem;
  font-size: 0.42666667rem;
  font-weight: 600;
  text-align: center;
}
.report-control .content .submit i {
  font-size: 0.32rem;
  line-height: 1.16rem;
}
.report-control .content .submit span {
  font-size: 0.42666667rem;
  margin: 0 .08rem;
  line-height: 1.16rem;
}
.share {
  position: fixed;
  top: 0;
  right: 0;
  width: 6rem;
}
.share-img {
  width: 100%;
}
.share-btn {
  width: 60%;
  margin: .5rem 0 0 20%;
}
.ticket {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.ticket .content {
  width: 7.8rem;
  padding: .4rem 0;
  border-radius: .16rem;
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -2rem;
}
.ticket .content p {
  font-size: 0.48rem;
  text-align: center;
  font-weight: 600;
  padding-top: .4rem;
}
.ticket .content .img {
  width: 4.6rem;
  display: block;
  padding: .2rem;
  box-sizing: border-box;
  border: .1rem solid #5ec2b5;
  margin: 1rem 0 .4rem;
  border-radius: .4rem;
}
.ticket .content .d {
  margin-top: .4rem;
  font-size: 0.42666667rem;
  color: #5ec2b5;
  display: block;
  text-align: center;
  padding-bottom: .4rem;
}
