.fs(@font-size) {
    font-size: @font-size / 37.5rem;
}

@info: #cdac7a;







































.html_3              {
    img             {display: inline-block!important;max-width: 100%!important;}
}
