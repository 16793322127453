.fs(@font-size) {
    font-size: @font-size / 37.5rem;
}

@info: #cdac7a;
























































.page                       {margin: 0 .4rem;position: relative;z-index: 10;
    .box                    {border-radius: .4rem;border: 1rpx solid #ccc;background: #FFF;margin-bottom: .4rem;
        .hd                 {display: flex;justify-content: center;
            span            {color: #FFF;.fs(15);font-weight: 600;background: #3b3bd2;padding: .2rem .6rem;border-radius: 0 0 .2rem .2rem;}
        }
        .sm                 {padding: 0 .4rem;color: #333;margin-top: .4rem;.fs(14);}
    }
}
