.fs(@font-size) {
    font-size: @font-size / 37.5rem;
}

@info: #cdac7a;
















































.html_5             {
    img             {display: inline-block!important;max-width: 100%!important;}
    section         {margin-bottom: 0!important;}
    div             {.fs(14);}
    span,p          {.fs(14)!important;line-height: .66rem;color: #666;}
}
